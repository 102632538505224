import React from "react";
import LoadingLogo from "../../Assets/Logo/lloding_logo.png";
import GradientCircularProgress from "../LoadingProgressBar/index";

function Loading() {
    return (
        <div className="flex flex-col w-full h-[100dvh] justify-center items-center">
            <div className="flex w-full flex-col h-full justify-center items-center">
                <div className="flex flex-col items-center">
                    <img
                        src={LoadingLogo}
                        alt=""
                        style={{
                            width: "80%",
                            height: "auto",
                            objectFit: "cover"
                        }}
                    />
                    <h2 className="mt-6 text-center text-3xl">Vote. Play. Win.</h2>
                </div>
                <div className="mt-[30%]">
                <GradientCircularProgress />
            </div>
            </div>
        </div>
    );
}

export default Loading;
