import React from "react";
import kamharish from '../../Assets/Home/Choose1.jpg';
import DolandTrump from '../../Assets/Home/Choose2.png';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from "../../api";
import { Button } from '@mui/material';
import { setUser } from "../../reducer";
import CheckIcon from '@mui/icons-material/Check';

function Choose({ setActiveComponent }) {
  const dispatch = useDispatch();
  const candidate = useSelector((state) => state.user.selectCryptoTraditional);
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleOkayButton = () => {
    setActiveComponent(3);
    updateUser({ ...userInfo, balance: userInfo.balance + 1000 });
    dispatch(setUser({ ...userInfo, balance: userInfo.balance + 1000 }));
  };

  return (
    <div className="p-5 flex w-full justify-center items-center">
      <div className="text-center flex flex-col items-center">
        <p className="w-full font-bold text-xl max-w-[314px] mb-5">Congratulations</p>
        <h2 className="font-bold text-[28px] w-full max-w-[282px] mb-5">{`You voted: ${candidate.name}`}</h2>
        <p className="w-full max-w-[282px] mb-5 text-center">Pick a topic and join an alliance.</p>
        
        <div className={`relative w-[11rem] h-[11rem] rounded-full border-[1rem] border-${candidate.id === 1 ? "blue-500" : "[#f63b3b]"} flex items-center justify-center`}>
          <img
            width={150}
            height={150}
            src={candidate.id === 1 ? kamharish : DolandTrump}
            alt="Profile"
            className="w-[10rem] h-[10rem] rounded-[5.37rem] object-cover"
          />
          <CheckIcon
            style={{
              position: "absolute",
              top: "90%",
              right: "10%",
              zIndex: 1,
              backgroundColor: "white",
              borderRadius: "50%",
              padding: "4px",
              color: "green",
            }}
          />
        </div>
        
        <p className="w-full max-w-[186px]">Congrats! </p>
        <p className="w-full max-w-[186px] mb-5">You gained 1000 Liberty points.</p>
        <Button
          variant="contained"
          onClick={handleOkayButton}
          fullWidth
          sx={{
            backgroundColor: '#87CEEB', // light sky blue color
            color: 'white',
            borderRadius: '20px',
            marginTop: '5%',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#6495ED', // slightly darker shade on hover
            },
          }}
        >
          Join the Battle
        </Button>
      </div>
    </div>
  );
}

export default Choose;
