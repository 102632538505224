import * as React from 'react';
import Card from '@mui/material/Card';
import { IconButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import HomeCardOne from '../../Assets/Home/Home_Card1.jpg';
import HomeCardTwo from '../../Assets/Home/Home_Card2.webp';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';

export default function ActionAreaCard({ setActiveComponent }) {
  const candidate = useSelector((state) => state.user.selectCryptoTraditional);

  return (
    <div className="flex flex-col gap-4">
       <h2 style={{ fontSize: '1.5em' }}><strong>Cypto</strong></h2>
      <Card
        sx={{
          boxShadow: 20, // Increase this value for a stronger shadow
          transition: '0.2s',
        }}>
        <CardActionArea onClick={() => candidate ? setActiveComponent(3) : setActiveComponent(1)}>
          <CardMedia
            component="img"
            height="140"
            image={HomeCardTwo}
            alt="green iguana"
          />
          <CardContent>
            <p className='text-xl font-bold'>Crypto vs traditional finance</p>
            <p>Who holds the future of economy?</p>
          </CardContent>
        </CardActionArea>
      </Card>
      <h2 style={{ fontSize: '1.5em' }}><strong>Debate</strong></h2>
      <Card
        sx={{
          boxShadow: 20, // Increase this value for a stronger shadow
          transition: '0.2s',
        }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={HomeCardOne}
            alt="green iguana"
          />
          <CardContent>
            <p className='text-xl font-bold'>US Presidential Election 2024</p>
            <p>Kamala Harris or Donald Trump — who will be the next president?</p>
            <p className='text-lg font-bold'>Battle ends Nov 2024</p>
          </CardContent>
          <div
            className='flex items-center justify-center'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '4px'
            }}
          >
            <LockIcon fontSize="large" />
          </div>
        </CardActionArea>
      </Card>

      {/* <h2 style={{ fontSize: '1.5em' }}><strong>Conflicts</strong></h2>
      <Card
        sx={{
          boxShadow: 20, // Increase this value for a stronger shadow
          transition: '0.2s',
        }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={HomeCardTwo}
            alt="green iguana"
          />
          <CardContent>
            <p className='text-xl font-bold'>Ukraine vs Russia</p>
            <p>Which country will achieve its war goals first?</p>
            <p className='text-lg font-bold'>Feb 2022 - Ongoing</p>
          </CardContent>
          <div
            className='flex items-center justify-center'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '4px'
            }}
          >
            <LockIcon fontSize="large" />
          </div>
        </CardActionArea>
      </Card> */}
    </div >
  );
}
