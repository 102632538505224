import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

function RiddleGame({ setActiveComponent }) {
    const [riddle, setRiddle] = React.useState(0);

    const handleNextClickButton = () => {
        if (riddle === 0) {
            toast.error("Please select a riddle game")
        } else {
            if (riddle === 2) {
                setActiveComponent(8)
            }
            if (riddle === 1) {
                setActiveComponent(5)
            }
        }
    }
    return (
        <div className="flex flex-col">
            <div>
                <IconButton size="large" onClick={() => setActiveComponent(3)}>
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </div>
            <div className="text-center mt-[1dvh]">
                <h1 className="text-[3dvh] font-bold text-black-800 ">Riddle Game</h1>
                <p className="mt-[1dvh] text-black-500 max-w-md mx-auto text-[2dvh]">
                    Play games to earn Liberty points and claim victory.
                </p>
            </div>
            <div className="text-lg text-center font-bold text-black-800 mt-[1dvh] text-[2dvh]">Choose Game Type</div>
            <div className="flex items-center justify-around space-x-4 mt-5">
                <div onClick={() => setRiddle(1)} className={`w-[15dvh] h-[15dvh] bg-white ${riddle === 1 ? "border-8" : "border-2"} border-blue-500 rounded-full flex items-center justify-center hover:shadow-lg transition-shadow duration-300`}>
                    <span className="text-center font-semibold text-black-500 text-[2dvh]">Crypto<br /> Economics</span>
                </div>

                <div onClick={() => setRiddle(2)} className={`w-[15dvh] h-[15dvh] bg-white ${riddle === 2 ? "border-8" : "border-2"} border-blue-500 rounded-full flex items-center justify-center hover:shadow-lg transition-shadow duration-300`}>
                    <span className="text-center font-semibold text-black-500 text-[2dvh]">Debate<br />Duel</span>
                </div>
            </div>
            <div className="flex justify-between mt-4">
                <div className="border-r border-gray-300 pr-4 flex items-center justify-center">
                    <p className="text-black-500 max-w-md text-center text-[2dvh]">Solve math problems related to survey data, approval ratings, and demographic trends to earn Liberty points for your alliance.</p>
                </div>
                <div className="pl-4 flex items-center justify-center">
                    <p className="text-black-500 max-w-md text-center text-[2dvh]">Outsmart your opponents by answering trivia questions related to cryptocurrency and trading finance, and claim victory by demonstrating your market expertise.</p>
                </div>
            </div>
            <div className="flex justify-center mt-10">
                <Button variant="contained"
                    onClick={() => handleNextClickButton()}
                    fullWidth
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        marginTop: '5%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    NEXT
                </Button>
            </div>
        </div>)
}

export default RiddleGame;