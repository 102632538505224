import * as React from 'react';
import kamharish from '../../Assets/Home/Choose1.jpg';
import DolandTrump from '../../Assets/Home/Choose2.png';
import BackButton from "../../Components/Button/BackButton";
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setCandidate, setUser, setCrypto } from "../../reducer";
import CheckIcon from '@mui/icons-material/Check';
import { updateUser } from "../../api";

export default function ChosseCandidate({ setActiveComponent }) {
  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const [isChoose1Hovered, setIsChoose1Hovered] = React.useState(false);
  const [isChoose2Hovered, setIsChoose2Hovered] = React.useState(false);
  const [condidate, setCondidate] = React.useState("");

  const handleVoteButton = () => {
    if (condidate === "") {
      toast.error("Please select a candidate");
    } else {
      dispatch(setCrypto(condidate));
      setActiveComponent(2);
      updateUser({ ...userInfo, selectCryptoTraditional: condidate.id });
      dispatch(setUser({ ...userInfo, selectCryptoTraditional: condidate.id }));
    }
  };

  return (
    <div className='w-full h-[100dvh] flex flex-col items-center justify-center'>
      <BackButton handleBackClick={() => setActiveComponent(0)} />
      <div className="basis-1/5 text-center p-[5%]">
        <h1><strong className='text-2xl'>Block Your Vote</strong></h1>
        <p>Choose an alliance..</p>
      </div>
      <div className='basis-1/3 flex flex-row items-center justify-center'>
        <div
          style={{ position: 'relative' }}
          onMouseOver={() => setIsChoose1Hovered(true)}
          onMouseOut={() => setIsChoose1Hovered(false)}
          onClick={() => setCondidate({ id: 1, name: "Crypto" })}
        >
          <div className={`w-[11rem] h-[11rem] rounded-full ${isChoose1Hovered || (condidate && condidate.id === 1) ? "border-[1rem]" : ""} border-blue-500 flex items-center justify-center`}>
            {/* Image */}
            <img
              width={210}
              height={210}
              src={kamharish} // Replace with your image path
              alt="Profile"
              style={{
                transform: isChoose1Hovered || (condidate && condidate.id === 1) ? 'scale(1)' : 'scale(0.9)',
                transition: 'transform 0.2s'
              }}
              className="w-[10rem] h-[10rem] rounded-[5.37rem] object-cover"
            />
          </div>
          <div className='flex justify-center items-center'>
            <p className='font-bold'>Crypto</p>
          </div>
          {condidate && condidate.id === 1 && (
            <CheckIcon
              fontSize="large"
              style={{
                position: 'absolute',
                bottom: 20,
                right: 30,
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '4px',
                color: 'green',
              }}
            />
          )}
        </div>
        <div
          style={{ position: 'relative' }}
          onMouseOver={() => setIsChoose2Hovered(true)}
          onMouseOut={() => setIsChoose2Hovered(false)}
          onClick={() => setCondidate({ id: 2, name: "Traditional Finance" })}
        >
          <div className={`w-[11rem] h-[11rem] rounded-full ${isChoose2Hovered || (condidate && condidate.id === 2) ? "border-[1rem]" : ""} border-[#f63b3b] flex items-center justify-center`}>
            {/* Image */}
            <img
              width={150}
              height={150}
              src={DolandTrump} // Replace with your image path
              alt="Profile"
              style={{
                transform: isChoose2Hovered || (condidate && condidate.id === 2) ? 'scale(1)' : 'scale(0.9)',
                transition: 'transform 0.2s'
              }}
              className="w-[10rem] h-[10rem] rounded-[5.37rem] object-cover"
            />
          </div>
          <div className='flex items-center justify-center'>
            <p className='font-bold'>Traditional Finance</p>
          </div>
          {condidate && condidate.id === 2 && (
            <CheckIcon
              fontSize="large"
              style={{
                position: 'absolute',
                bottom: 20,
                right: 30,
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '4px',
                color: 'green',
              }}
            />
          )}
        </div>
      </div>
      <div className='basis-1/3 w-[80%] flex flex-col items-center'>
        <Button variant="contained"
          onClick={() => handleVoteButton()}
          fullWidth
          sx={{
            backgroundColor: '#87CEEB', // light sky blue color
            color: 'white',
            borderRadius: '20px',
            marginTop: '5%',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#6495ED', // slightly darker shade on hover
            },
          }}>
          Vote
        </Button>
      </div>
    </div>
  );
}
