import React from 'react';
import HomeCardOne from '../../Assets/Home/Home_Card1.jpg';

const ElectionComponent = ({ candidate1, candidate2, votes1, votes2, selectedCandidate }) => {
    const totalVotes = votes1 + votes2;
    const percentage1 = (votes1 / totalVotes) * 100;
    const percentage2 = (votes2 / totalVotes) * 100;

    return (
        <div className="relative w-80 h-52 bg-gray-100 rounded-lg overflow-hidden shadow-md">
            {/* Background Image */}
            <div className="absolute inset-0">
                <img
                    src={HomeCardOne} // Replace with the flag image URL
                    alt="US Flag"
                    className="w-full h-full object-cover"
                />
            </div>

            {/* Overlay Header */}
            <div className="absolute top-0 left-0 w-full p-4 bg-gradient-to-b from-black/60 to-transparent text-white">
                <h3 className="font-semibold text-lg">US President Elections</h3>
                <p className="text-sm">November 2024</p>
            </div>

            {/* Progress Bar */}
            <div className="absolute bottom-0 left-0 w-full bg-white p-4 flex items-center justify-between">
                {/* Candidate 1 */}
                <div className="flex items-center">
                    <span className={`font-semibold ${selectedCandidate === candidate1 ? 'text-blue-500' : 'text-gray-600'}`}>
                        {candidate1}
                    </span>
                    {selectedCandidate === candidate1 && <span className="ml-1 text-green-500">✓</span>}
                </div>

                {/* Vote Count */}
                <div className="flex-grow mx-3">
                    <div className="relative w-full h-2 bg-gray-300 rounded-full overflow-hidden">
                        <div
                            className={`absolute top-0 left-0 h-full ${selectedCandidate === candidate1 ? 'bg-blue-500' : 'bg-gray-400'}`}
                            style={{ width: `${percentage1}%` }}
                        ></div>
                        <div
                            className={`absolute top-0 right-0 h-full ${selectedCandidate === candidate2 ? 'bg-red-500' : 'bg-red-500'}`}
                            style={{ width: `${percentage2}%` }}
                        ></div>
                    </div>
                    <div className="text-center text-sm text-gray-600 mt-1">
                        {votes1.toLocaleString()}/{votes2.toLocaleString()}
                    </div>
                </div>

                {/* Candidate 2 */}
                <span className={`font-semibold ${selectedCandidate === candidate2 ? 'text-red-500' : 'text-gray-600'}`}>
                    {candidate2}
                </span>
            </div>
        </div>
    );
};

export default ElectionComponent;
