import React from "react";
import { quizData } from "../../TextGameFile/index";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import Button from "@mui/material/Button";
import { toast } from 'react-toastify';
import { updateUser } from "../../api";
import { setUser } from "../../reducer";

function TextLevel({ setActiveComponent }) {
    const userInfoRiddle = useSelector((state) => state.user.userInfo.textCryptoGame);
    const userInfo = useSelector((state) => state.user.userInfo);
    const [selectLevel, setSelectLevel] = React.useState(1);
    const dispach = useDispatch()

    const handleComplexityLevelClick = (complexityLevel) => {
        setSelectLevel(complexityLevel.level);
    }

    const handleStartClick = () => {
        if (userInfoRiddle === undefined || userInfoRiddle.level === 0) {
            if (selectLevel === 1) {
                const updateUserInfo = { ...userInfo, balance: userInfo.balance - quizData.find(key => key.level === selectLevel).entryCost, textCryptoGame: { ...userInfoRiddle, level: selectLevel } }
                updateUser(updateUserInfo)
                dispach(setUser(updateUserInfo))
                toast.info("level unlock!!");
                setActiveComponent(9)
            } else {
                toast.error("Please unlock the previous level");
            }
        } else if (selectLevel - userInfoRiddle.level === 1 && userInfoRiddle.levelDone === 10) {
            const updateUserInfo = { ...userInfo, balance: userInfo.balance - quizData.find(key => key.level === selectLevel).entryCost, textCryptoGame: { ...userInfoRiddle, level: selectLevel } }
            updateUser(updateUserInfo)
            dispach(setUser(updateUserInfo))
            toast.info("level unlock!!");
            setActiveComponent(9)
        } else if (selectLevel === userInfoRiddle.level) {
            setActiveComponent(9)
        } else {
            toast.error("Please unlock the previous level");
        }
    }


    return (
        <div className="flex flex-col gap-4 basis-full">
            <div className="flex flex-col items-start justify-start">
                <IconButton size="large" onClick={() => setActiveComponent(4)}>
                    <ArrowBackIcon fontSize="inherit" />
                </IconButton>
            </div>
            <h1 className="text-2xl font-bold text-black-800 basis-1/5 text-center">Debate Duel</h1>
            {(userInfo.textCryptoGame !== undefined && userInfo.textCryptoGame.level === 3 && userInfo.textCryptoGame.levelDone === 10) ? <p className="text-sm text-center mb-5">all levels are completed</p> : (<>
                <p className="text-sm text-center mb-5">Set puzzle difficulty.</p>
                {quizData.map((item) => {
                    return (
                        <div onClick={() => handleComplexityLevelClick(item)} className={`border-[3px] ${selectLevel === item.level ? "border-[#0597FF]" : ""} flex p-2 basis-2/12`}>
                            <span className="flex basis-1/2 w-full text-left text-black font-[Roboto] text-base">{`${item.level === 1 ? "Easy Puzzle" :
                                item.level === 2 ? "Normal Puzzle" :
                                    item.level === 3 ? "Hard Puzzle" :
                                        item.level === 4 ? "Expert Puzzle" :
                                            `Complexity Lvl ${item.level}`
                                }`}</span>
                            <div className="flex basis-1/2 flex-col items-end">
                                <span className="text-right text-black font-[w-500][Roboto] text-sm">{`Entry cost ${item.entryCost} coins`}</span>
                                <span className="text-right text-gray-500 font-[Roboto] text-xs">{`Win ${item.wincose} coins`}</span>
                            </div>
                        </div>)
                })}
            </>)}
            {(userInfo.textCryptoGame !== undefined && userInfo.textCryptoGame.level === 3 && userInfo.textCryptoGame.levelDone === 10) ? "" : <div className="flex basis-">
                <Button variant="contained"
                    onClick={() => handleStartClick(6)}
                    fullWidth
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        marginTop: '5%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    Start
                </Button>
            </div>}
        </div >
    );
}

export default TextLevel;
