import React from "react";
import sqrImages from "../../Assets/images/Square.png"

function FriendList({ data }) {
  return (
    <>
        {/* Friends List */}
        <div className="w-full mt-4 space-y-4">
          {/* Friend 1 */}
          <div className="flex items-center justify-between border-b pb-4">
            <div className="flex items-center space-x-4">
              <img
                className="w-10 h-10 rounded-full"
                src={sqrImages}
                alt="Dean"
              />
              <span className="text-black font-bold opacity-80">{data.firstName}</span>
            </div>
          </div>
        </div>
    </>
  );
}

export default FriendList;
