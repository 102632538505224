// slices/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    tour: false,
    chooseCandidate: false,
    selectCryptoTraditional: false
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload; // Set the user info
    },
    clearUser: (state) => {
      state.userInfo = null; // Clear the user info
    },
    setTour: (state, action) => {
      state.tour = action.payload
    },
    setCandidate: (state, action) => {
      state.chooseCandidate = action.payload
    },
    setCrypto: (state, action) => {
      state.selectCryptoTraditional = action.payload
    }
  },
});

export const { setUser, clearUser, setTour, setCandidate, setCrypto } = userSlice.actions;
export default userSlice.reducer;
