import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

export default function DotsMobileStepper({ activeStep }) {
  return (
    <MobileStepper
      variant="dots"
      steps={3}
      position="static"
      activeStep={activeStep}
      sx={{ maxWidth: 400, flexGrow: 1 }}
    />
  );
}
