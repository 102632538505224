import React from "react";
import TourOneLogo from "../../Assets/TourScreen/Screen1.png";
import DotsMobileStepper from "../Stepper/index";
import Button from '@mui/material/Button';

function TourOne({ setActiveStep, setSkip }) {
    return (
        <div className="flex flex-col w-full h-[100dvh] justify-between">
            <div className="flex w-full flex-col p-[5%] mt-[10%]">
                <div className="flex w-[60%]">
                    <img
                        src={TourOneLogo}
                        alt=""
                        style={{
                            width: "80%",
                            height: "auto",
                            objectFit: "cover"
                        }}
                    />
                </div>
                <div className="p-[2%]">
                    <p className="text-2xl font-bold pt-[2%] mb-[2%]">Pick Your Side</p>
                    <p className="text-xl  font-roboto">Choose a topic you’re passionate about, like major events or classic rivalries.</p>
                </div>
                <div className="p-[2%]">
                    <DotsMobileStepper activeStep={0} />
                </div>
            </div>
            <div className="p-[5%] flex flex-row w-full justify-between">
                <Button onClick={() => setSkip()} variant="text">Skip</Button>
                <Button variant="contained"
                  onClick={() => setActiveStep()}
                 sx={{
                    backgroundColor: '#87CEEB', // light sky blue color
                    color: 'white',
                    borderRadius: '20px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#6495ED', // slightly darker shade on hover
                    },
                }}>
                    Next
                </Button>
            </div>
        </div>
    );
}

export default TourOne;
