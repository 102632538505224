import React from 'react';
import Button from '@mui/material/Button';
import arrow from "../../Assets/Logo/left-arrow.png"

const BackButton = ({ handleBackClick }) => {
  return (
    <div>
      <img
        src={arrow}
        alt="Back"
        onClick={() => handleBackClick()}
        className="cursor-pointer absolute top-5 left-5 w-7 h-7"
      />
    </div>
  );
};

export default BackButton;
