import axios from "axios";

export const updateUser = async (userInfo) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/updateUser`,
      {
        ...userInfo
      }
    );
    return data;
  } catch (error) {
    console.error("Error creating user:", error);
  }
};


export const getLeaderBoardData = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/getleaderboard`,
    );
    return data;
  } catch (error) {
    console.error("Error creating user:", error);
  }
};