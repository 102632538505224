import * as React from 'react';
import Card from '@mui/material/Card';
import { IconButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import HomeCardOne from '../../Assets/Home/Home_Card1.jpg';
import HomeCardTwo from '../../Assets/Home/Home_Card2.webp';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';

export default function ActionAreaCard({ setActiveComponent }) {
  const candidate = useSelector((state) => state.user.chooseCandidate);

  return (
    <div className="flex flex-col gap-4">
       <h2 style={{ fontSize: '1.5em' }}><strong>Cypto Watch</strong></h2>
      <Card
        sx={{
          boxShadow: 20, // Increase this value for a stronger shadow
          transition: '0.2s',
        }}>
        <CardActionArea onClick={() => setActiveComponent(2)}>
          <CardMedia
            component="img"
            height="140"
            image={HomeCardTwo}
            alt="green iguana"
          />
          <CardContent>
            <p className='text-xl font-bold'>Crypto vs traditional finance</p>
            <p>LeaderBoard Crypto Watch</p>
          </CardContent>
        </CardActionArea>
      </Card>
      <h2 style={{ fontSize: '1.5em' }}><strong>Debate Watch</strong></h2>
      <Card
        onClick={() => setActiveComponent(1)}
        sx={{
          boxShadow: 20, // Increase this value for a stronger shadow
          transition: '0.2s',
        }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={HomeCardOne}
            alt="green iguana"
          />
          <CardContent>
            <p className='text-xl font-bold'>US Presidential Election 2024</p>
            <p>LeaderBoard Debate Watch</p>
            <p className='text-lg font-bold'>Battle ends Nov 2024</p>
          </CardContent>
        </CardActionArea>
      </Card>
    </div >
  );
}
