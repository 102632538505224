import './App.css';
import { Provider } from 'react-redux';
import TelegramProvider from "./TelegramProvider";
import AppRouter from './AppRouter';
import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <TelegramProvider>
        <AppRouter />
      </TelegramProvider>
    </Provider>
  );
}

export default App;
