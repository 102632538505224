// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducer';

export const store = configureStore({
  reducer: {
    user: userReducer, // Add user slice to the store
  },
});

export default store;
