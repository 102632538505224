import React from 'react';
import axios from 'axios';
import HomeCardOne from '../../Assets/Home/Home_Card1.jpg';
import Group2 from '../../Assets/Home/Group2.png';
import calender from '../../Assets/img/Calendar.png';
import telegram from '../../Assets/img/telegram.png';
import { setUser } from '../../reducer';
import { useDispatch, useSelector } from 'react-redux';
import EarnMore from "../../Components/Earnmore/index";

export default function RewardAndTasks() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const [earnMore, setEarnmore] = React.useState(false);
  const [userStreak, setUserStreak] = React.useState({
    _id: "",
    day: 0,
    upcoming: 0,
    user: "",
    updatedAt: "",
  });

  React.useEffect(() => {
    fetchStreakInfo();
  }, [userInfo]);

  const fetchStreakInfo = async () => {
    if (userInfo) {
      const user = await axios.get(
        `${process.env.REACT_APP_API_URL}/userStreak`,
        {
          params: {
            _id: userInfo._id,
          },
        }
      );
      if (user.data) {
        setUserStreak(user.data);
      }
    }
  };

  const handleSocial = async (social) => {
    if (userInfo) {
      const data = await axios.post(`${process.env.REACT_APP_API_URL}/updateSocial`, {
        _id: userInfo._id,
        social: social,
      });
      dispatch(setUser(data.data))
    }
  };
  return (
    <div className="flex flex-col basis-full gap-4 mt-7 min-h-[88dvh] max-h-[88dvh]">
      <div className="flex flex-col items-center mb-1">
        <img
          src={HomeCardOne}
          alt="Person"
          className="w-[10dvh] h-[10dvh] rounded-full mb-2"
        />
        <span>{userInfo?.firstName}</span>
      </div>
      <div className="flex flex-col items-center">
        <img
          src={Group2}
          alt="Person"
          className="w-[20dvh] h-[20dvh] rounded-full mb-3"
        />
        <span className="font-bold"><strong>{`Liberty points: ${userInfo?.balance} coins`}</strong></span>
        <div className="flex flex-col w-full mt-2 px-4">
          <h2 className="text-md font-semibold">Daily Rewards</h2>
          <div className="flex items-center justify-between pb-3 mt-1 bg-gray-100 rounded-lg">
            <div className="flex items-center" onClick={() => setEarnmore(true)}>
              <img
                src={calender}
                alt="Reward Logo"
                className="w-8 h-8 rounded-full m-2"
              />
              <div className="flex flex-col items-center">
                <span className="font-bold text-sm">Today’s Reward</span>
              </div>
            </div>
            <span className="text-green-500 font-semibold text-lg">✔️</span>
          </div>
        </div>

        <div className="flex flex-col w-full mt-2 px-4 bg-gray-50 rounded-lg">
          <h2 className="text-md font-semibold">Reward Challenges</h2>
          <button
            type="button"
            role="link"
            className="flex items-center justify-between pb-3 mt-3 bg-gray-100 rounded-lg"
            onClick={() => {
              !userInfo?.social.x &&
                handleSocial({ ...userInfo?.social, x: true });
              window.open("https://x.com/Block_The_Vote")
            }
            }
          >
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 29"
                fill="none"
              >
                <path
                  d="M17.2463 12.4232L26.036 2.42552H23.9532L16.321 11.1064L10.2252 2.42552H3.19446L12.4125 15.5525L3.19446 26.0366H5.27747L13.3372 16.8694L19.7748 26.0366H26.8056L17.2458 12.4232H17.2463ZM14.3933 15.6682L13.4593 14.361L6.02801 3.95987H9.22739L15.2246 12.3539L16.1585 13.6611L23.9541 24.5721H20.7548L14.3933 15.6687V15.6682Z"
                  fill="black"
                />
              </svg>
              <div className="flex flex-col pl-6 item-center">
                <span className="font-bold text-sm text-center">Follow us on X</span>
                <span className="text-xs text-gray-500 text-start">+25,000</span>
              </div>
            </div>
            <span>
              {userInfo?.social.x ? (
                <>
                  ✔️
                </>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M4 10.2311L16 10.2311M16 10.2311L11.7574 6.23108M16 10.2311L11.7574 14.2311"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>
        <div className="flex flex-col w-full mt-2 px-4 bg-gray-50 rounded-lg">
          <button
            type="button"
            role="link"
            className="flex items-center justify-between pb-3 mt-3 bg-gray-100 rounded-lg"
            onClick={() => {
              !userInfo?.social.discord &&
                handleSocial({ ...userInfo?.social, discord: true });
              window.open("https://discord.com/invite/zrYWVyxjd9")
            }
            }
          >
            <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 50 51"
              fill="none"
            >
              <path
                d="M38.0437 13.1111C42.2789 19.4673 44.3704 26.6369 43.5885 34.8907C43.5853 34.9257 43.5675 34.9577 43.5393 34.9788C40.3321 37.3827 37.2248 38.8416 34.1611 39.8091C34.1372 39.8165 34.1117 39.8161 34.0881 39.8079C34.0644 39.7998 34.0439 39.7843 34.0293 39.7636C33.3215 38.7583 32.6784 37.6985 32.115 36.5855C32.0826 36.52 32.1122 36.441 32.1787 36.4152C33.2001 36.0224 34.1714 35.5515 35.1056 34.9941C35.1792 34.9501 35.1839 34.8424 35.1159 34.7907C34.9176 34.6405 34.7212 34.4826 34.5333 34.3247C34.4981 34.2955 34.4508 34.2898 34.4109 34.3094C28.3458 37.1683 21.7017 37.1683 15.5648 34.3094C15.525 34.2912 15.4777 34.2974 15.4434 34.3261C15.2559 34.484 15.0591 34.6405 14.8627 34.7907C14.7947 34.8424 14.8003 34.9501 14.8744 34.9941C15.8086 35.541 16.7798 36.0224 17.7998 36.4171C17.8659 36.4429 17.8973 36.52 17.8645 36.5855C17.3133 37.6999 16.6702 38.7598 15.9492 39.7651C15.9178 39.8057 15.8663 39.8244 15.8175 39.8091C12.7683 38.8416 9.66095 37.3827 6.45377 34.9788C6.42705 34.9577 6.40783 34.9242 6.40502 34.8893C5.75158 27.7498 7.0833 20.5209 11.9447 13.1096C11.9564 13.09 11.9742 13.0747 11.9949 13.0656C14.3869 11.945 16.9495 11.1206 19.628 10.6498C19.6767 10.6421 19.7255 10.6651 19.7508 10.7091C20.0817 11.3072 20.46 12.0742 20.7159 12.701C23.5392 12.2608 26.4065 12.2608 29.2889 12.701C29.5448 12.0876 29.91 11.3072 30.2395 10.7091C30.2512 10.6873 30.2694 10.6698 30.2915 10.6591C30.3135 10.6484 30.3383 10.6452 30.3623 10.6498C33.0422 11.122 35.6048 11.9465 37.995 13.0656C38.0161 13.0747 38.0334 13.09 38.0437 13.1111ZM22.1512 26.6857C22.1808 24.5751 20.6733 22.8286 18.7809 22.8286C16.9041 22.8286 15.4111 24.5598 15.4111 26.6857C15.4111 28.8111 16.9336 30.5423 18.7809 30.5423C20.6583 30.5423 22.1512 28.8111 22.1512 26.6857ZM34.6115 26.6857C34.6411 24.5751 33.1336 22.8286 31.2417 22.8286C29.3644 22.8286 27.8714 24.5598 27.8714 26.6857C27.8714 28.8111 29.3939 30.5423 31.2417 30.5423C33.1336 30.5423 34.6115 28.8111 34.6115 26.6857Z"
                fill="black"
              />
            </svg>
              <div className="flex flex-col items-ceneter pl-6">
                <span className="font-bold text-sm text-center">Join Block The Vote’s Discord</span>
                <span className="text-xs text-gray-500 text-start">+25,000</span>
              </div>
            </div>
            <span>
              {userInfo?.social.discord ? (
                <>
                  ✔️
                </>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M4 10.2311L16 10.2311M16 10.2311L11.7574 6.23108M16 10.2311L11.7574 14.2311"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>
        <div className="flex flex-col w-full mt-2 px-4 bg-gray-50 rounded-lg">
          <button
            type="button"
            role="link"
            className="flex items-center justify-between pb-3 mt-3 bg-gray-100 rounded-lg"
            onClick={() => {
              !userInfo?.social.discord &&
                handleSocial({ ...userInfo?.social, tg: true });
              window.open("https://t.me/Block_The_Vote")
            }
            }
          >
            <img
                src={telegram}
                alt="Reward Logo"
                className="w-8 h-8 rounded-full m-2"
              />
            <div className="flex items-center">
            
              <div className="flex flex-col items-ceneter pl-6">
                <span className="font-bold text-sm text-center">Join Block The Vote’s Telegram</span>
                <span className="text-xs text-gray-500 text-start">+25,000</span>
              </div>
            </div>
            <span>
              {userInfo?.social.tg ? (
                <>
                  ✔️
                </>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M4 10.2311L16 10.2311M16 10.2311L11.7574 6.23108M16 10.2311L11.7574 14.2311"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </span>
          </button>
        </div>
        {earnMore && <EarnMore
          setEarnmore={setEarnmore}
          userStreak={userStreak}
          fetchStreakInfo={fetchStreakInfo} />}
      </div>
    </div>
  );
}