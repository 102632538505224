import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from "axios";
import Home from './Pages/Home';
import Choose from './Pages/Home/Choose';
import LeaderBoard from './Pages/LeaderBoard/index';
import RewardAndTasks from './Pages/Home/RewardAndTasks';
import Loading from "../src/Components/Loading/index";
import ChosseCandidate from "../src/Pages/ChooseCandidate/index";
import BottomNavigation from './Components/BottomNavigation/index';
import Refferals from './Pages/Refferals/index';
import { useDispatch, useSelector } from 'react-redux';
import { setTour, setUser, setCandidate, setCrypto } from "../src/reducer";
import { ToastContainer, } from 'react-toastify';
import { useTelegram } from "./TelegramProvider";
import 'react-toastify/dist/ReactToastify.css';

function AppRouter() {
  const dispatch = useDispatch();
  const { user } = useTelegram();
  const tour = useSelector((state) => state.user.tour);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    if (localStorage.getItem("tour") === "true") {
      dispatch(setTour(true));
    }
  }, []);

  
  React.useEffect(() => {
    if (userInfo) {
      // updateUser();
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (user) {
      createUser();
    }
  }, [user]);

  const createUser = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/createUser`,
        {
          userId: user.id,
          firstName: user.first_name,
          lastName: user.last_name,
        }
      );
      dispatch(setUser(data));
      if (data.selectCryptoTraditional !== 0) {
        let candidate = "";
        if (data.selectCryptoTraditional === 1) {
          candidate = { id: 1, name: "Crypto" };
        }
        if (data.selectCryptoTraditional === 2) {
          candidate = { id: 2, name: "Traditional Finance" };
        }
        dispatch(setCrypto(candidate));
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  return (
    <BrowserRouter>
      <div className='w-full h-[100dvh] min-h-[100dvh] max-h-[100dvh] flex bg-white flex-col'>
        <div className="flex max-h-[88dvh] min-h-[88dvh] overflow-auto">
          {/* Page Routes */}
          <Routes>
            <Route path="/" element={isLoading ? <Loading /> : <Home />} />
            <Route path="/choosecandidate" element={<ChosseCandidate />} />
            <Route path="choose" element={<Choose />} />
            <Route path="leader-board" element={<LeaderBoard />} />
            <Route path="/refferals" element={<Refferals />} />
            <Route path="earnings" element={<RewardAndTasks />} />
          </Routes>
        </div>

        {/* Bottom Navigation, always visible */}
        {!isLoading && tour && <BottomNavigation />}
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default AppRouter;
