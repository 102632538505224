
import { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import HomeCardOne from '../../Assets/Home/Home_Card1.jpg';
import HomeCardTwo from '../../Assets/Home/Home_Card2.png';
import ElectionComponent from '../../Components/LeaderBoardHeader/index';
import { getLeaderBoardData } from "../../api";

const images = [HomeCardOne, HomeCardTwo];
export default function LeaderBoardDebate({ setActiveComponent }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const getLeaderBoardDataCall = async () => {
    try {
      const response = await getLeaderBoardData();
      setData(response)
    } catch (err) {
      console.log(err, "error")
    }
  };

  useEffect(() => {
    getLeaderBoardDataCall();
  }, [])

  const condidate1 = data && data.filter(key => key.selectCandidateId === 1);
  const condidate2 = data && data.filter(key => key.selectCandidateId === 2);

  return (
    <div className="flex basis-full flex-col items-center max-h-[88dvh] min-h-[88dvh]">
      <div className='flex w-full'>
        <div className="flex">
          <IconButton size="small" onClick={() => setActiveComponent(0)}>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <h2 className='w-full text-center' style={{ fontSize: '1.5em' }}><strong>LeaderBoard Watch</strong></h2>
      </div>
      <div className="flex basis-full flex-col gap-4 items-center max-h-[85dvh] min-h-[85dvh]">
        <div className="relative flex items-center mt-7">
          <ElectionComponent
            candidate1="C. Harris"
            candidate2="D. Trump"
            votes1={condidate1 && condidate1.length}
            votes2={condidate2 && condidate2.length}
            selectedCandidate="C. Harris"
          />
        </div>
        <div className="flex flex-col mt-4 w-11/12 overflow-auto">
          <h3 className='font-bold mb-4'>LeaderBoard</h3>
          {data && data.filter(key => key.selectCandidateId !== undefined)
            .sort((a, b) => b.balance - a.balance) // Sorts by balance in descending order
            .map((item, index) => (
              <div key={index} className="flex items-center justify-between mb-2 border-b border-gray-300 pb-2">
                {console.log(item.selectCandidateId === undefined ? item : "")}
                <img src={images[currentIndex]} alt="Person" className="w-12 h-12 rounded-full" />
                <div className="flex flex-col ml-3 flex-grow">
                  <span className="font-bold">{item.firstName || "Person's Name"}</span>
                  <span className="text-sm text-gray-500">{item.description || "Short description about the person."}</span>
                </div>
                <span className="font-semibold">{item.balance}</span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}