import React, { useState } from 'react';
import { FaGamepad, FaTrophy, FaUsers, FaUser } from 'react-icons/fa'; // Icons
import { useNavigate } from 'react-router-dom';

const BottomNavigation = () => {
  const [active, setActive] = useState('battles');
  const navigate = useNavigate();

  const handleNavigation = (page) => {
    setActive(page);
    switch (page) {
      case 'battles':
        navigate('/'); // Home
        break;
      case 'leaderboard':
        navigate('/leader-board'); // LeaderBoard page
        break;
      case 'referrals':
        navigate('/refferals'); // Referrals/Rewards page
        break;
      case 'earnings':
        navigate('/earnings'); // Profile/Choose Candidate page
        break;
      default:
        navigate('/');
    }
  };

  return (
    <div className="basis-[12dvh] min-h-[12dvh] max-h-[12dvh]  bg-white shadow-md rounded-t-lg py-3 px-3 flex justify-around">
      <NavItem
        label="Battles"
        icon={<FaGamepad />}
        active={active === 'battles'}
        onClick={() => handleNavigation('battles')}
      />
      <NavItem
        label="Leaderboard"
        icon={<FaTrophy />}
        active={active === 'leaderboard'}
        onClick={() => handleNavigation('leaderboard')}
      />
      <NavItem
        label="Friends"
        icon={<FaUsers />}
        active={active === 'referrals'}
        onClick={() => handleNavigation('referrals')}
      />
      <NavItem
        label="Rewards"
        icon={<FaUser />}
        active={active === 'earnings'}
        onClick={() => handleNavigation('earnings')}
      />
    </div>
  );
};

// Navigation Item Component
const NavItem = ({ label, icon, active, onClick }) => (
  <button
    className={`flex flex-col items-center justify-center 
    ${active ? 'text-blue-500' : 'text-gray-500'} transition duration-300`}
    onClick={onClick}
  >
    <span className="text-xl">{icon}</span>
    <span className="text-sm">{label}</span>
  </button>
);

export default BottomNavigation;
