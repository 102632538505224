import React from "react";
import axios from "axios";
import sqrImages from "../../Assets/images/Square.png"
import copyImages from "../../Assets/images/Copy.png"
import { useSelector } from 'react-redux';
import LazyLoad from 'react-lazy-load';
import { useTelegram } from "../../TelegramProvider";
import { toast } from 'react-toastify';
import FriendList from "./friendList";

function Refferals() {
  const userInfo = useSelector((state) => state.user.userInfo);
  const { user } = useTelegram();
  const [friends, setFriends] = React.useState();

  const handleClick = async () => {
    if (userInfo && userInfo.userId) {
      const referralLink = `https://t.me/block_the_vote_bot?start=${userInfo.userId}`;
      try {
        if (!navigator.clipboard) {
          throw new Error("Clipboard API not supported");
        }
        await navigator.clipboard.writeText(referralLink);
        toast.success(`Link Copied to clipboard`);
      } catch (error) {
        // Fallback: Select and copy the text manually
        const textarea = document.createElement("textarea");
        textarea.value = referralLink;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand("copy");
          toast.success(`Link Copied to clipboard`);
        } catch (err) {
          console.log(err);
        }
        document.body.removeChild(textarea);
      }
    } else {
      console.log("User information is not available");
    }
  };

  React.useEffect(() => {
    fetchFriends();
  }, []);

  const fetchFriends = async () => {
    if (user) {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/userFriend`,
        {
          params: {
            userId: userInfo.userId,
          },
        }
      );
      if (data.data) {
        setFriends(data.data);
      }
    }
  };
  return (
    <>
      {/* Main Container */}
      <div className="flex flex-col basis-full items-center space-y-4 p-6 min-h-[88dvh] max-h-[88dvh]">
        {/* Invite Friends Heading */}
        <div className="text-center font-bold text-lg">Invite Friends</div>
        

        {/* Subtext Section */}
        <div className="text-center font-thin">
        Rally your team to join your alliance and earn
          <br />
          <span>even more rewards together.</span>
        </div>

        {/* Flex Row Section */}
        <div className="flex items-center space-x-4 mt-4">
          {/* Image */}
          <div className="flex-shrink-0">
            <img
              src={sqrImages}
              alt="Invite"
              className="w-16 h-16 mx-auto"
            />
          </div>

          {/* Text Section */}
          <div className="flex-grow text-left">
            <span className="font-bold">Invite Friends</span>
            <br />
            <span className="text-sm">+5 000 For you and for your friend</span>
          </div>
        </div>

        {/* Copy Referral Section */}
        <div className="w-full flex justify-between items-center bg-gray-100 p-2 rounded-lg">
          <span className="text-gray-500">Copy invitation link</span>
          <img src={copyImages} onClick={() => handleClick()} alt="Copy" className="w-6 h-6" />
        </div>

        {/* Tab Section */}
        <div className="flex space-x-4 bg-gray-100 p-2 rounded-full mt-6 w-full">
          {/* Friends Tab (Active) */}
          <button className="flex-1 text-white bg-gradient-to-r from-blue-400 to-cyan-400 rounded-full py-2 font-semibold">
          Friends
          </button>
          {/* Teams Tab */}
          <button className="flex-1 text-gray-500 bg-transparent rounded-full py-2 text-sm">
          Teams(coming  soon)
          </button>
        </div>

        {/* Friends List */}
        <div className="w-full mt-4 space-y-4 max-h-[35dvh] min-h-[35dvh] overflow-auto">
          {/* Friend 1 */}
          {friends && friends?.length > 0 ? (
            friends?.map((friend) => (
              <LazyLoad key={friend._id} threshold={0.5}>
                <FriendList data={friend} />
              </LazyLoad>
            ))
          ) : (
            <p>No Friends to show</p>
          )}
        </div>
        <button
          role="link"
          className="w-full max-w-[322px] h-[50px] p-[10px] rounded-[32px] border-3 border-transparent bg-gradient-to-b from-[#A1E5FB] to-[#1CB9EC] shadow-[0px_-4px_6px_#FFFFFF,0px_4px_6px_#FFFFFF] cursor-pointer text-white font-semibold"
          type="button"

          onClick={() => 
            window.open(`https://t.me/share/url?url=https://t.me/block_the_vote_bot?start=${userInfo.userId}&timestamp=${new Date().getTime()}`)
          }
        >
          Invite Friends</button>
      </div>
    </>
  );
}

export default Refferals;
