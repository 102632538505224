import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import ActionAreaCard from "./leaderBoardCard";
import CryptoDebet from "./CryptoCard";


import LeaderBoardDebate from "./LeaderBoard";
import { setTour } from "../../reducer";

function LeaderBoardCard() {
    const dispatch = useDispatch();
    const tour = useSelector((state) => state.user.tour);
    const [activeComponent, setActiveComponent] = React.useState(0);
    React.useEffect(() => {
        if (activeComponent === 2 && !tour)
            dispatch(setTour(true))
    }, [activeComponent])
    return (
        <div className="p-[5%] flex basis-full justify-center">
            {activeComponent === 0 && <ActionAreaCard setActiveComponent={(data) => setActiveComponent(data)} />}
            {activeComponent === 1 && <LeaderBoardDebate setActiveComponent={(data) => setActiveComponent(data)} />}
            {activeComponent === 2 && <CryptoDebet setActiveComponent={(data) => setActiveComponent(data)} />}
        </div>
    );
}

export default LeaderBoardCard;
