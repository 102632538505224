import React, { useState } from 'react';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { quizData } from '../../TextGameFile/index';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from "../../api";
import { setUser } from "../../reducer";
import 'react-toastify/dist/ReactToastify.css';

const QuizGameComponent = ({ setActiveComponent }) => {
    const userInfo = useSelector((state) => state.user.userInfo);
    const dispach = useDispatch();
    const userInfoRiddle = useSelector((state) => state.user.userInfo.textCryptoGame);
    const [currentLevel, setCurrentLevel] = useState(userInfoRiddle.level);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(userInfoRiddle.levelDone === undefined ? 0 : userInfoRiddle.levelDone);
    const [userAnswer, setUserAnswer] = useState('');
    const [nextLevelState, setNextLevelState] = useState(false);
    const [inputFieldValue, setInputFieldValue] = useState("");
    const currentLevelData = quizData.find(level => level.level === (currentLevel === undefined ? 1 : currentLevel));
    const currentQuestion = currentLevelData.questions[currentQuestionIndex === undefined ? 0 : currentQuestionIndex];


    const handleInputChange = (event) => {
        setUserAnswer(event.target.value);
        setInputFieldValue(currentQuestion.options[event.target.value])
    };

    const checkAnswer = () => {
        if (Number(userAnswer) === currentQuestion.answer) {
            if (currentQuestionIndex + 1 === 10) {
                setNextLevelState(true);
                toast.success('Correct answer! 🎉');
            } else {
                const updateUserInfo = { ...userInfo, balance: userInfo.balance + 500, textCryptoGame: { ...userInfoRiddle, levelDone: currentQuestionIndex + 1 } };
                toast.success('Correct answer! 🎉');
                setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % currentLevelData.questions.length);
                setUserAnswer("");
                setInputFieldValue("")
                updateUser(updateUserInfo);
                dispach(setUser(updateUserInfo))
            }
        } else {
            if (userInfo.balance - 100 >= 0) {
                const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, textCryptoGame: { ...userInfoRiddle, levelDone: currentQuestionIndex } }
                updateUser(updateUserInfo)
                dispach(setUser(updateUserInfo))
                setUserAnswer("");
                setInputFieldValue("")
                toast.error('Incorrect answer. 100 coin deducted! ❌');
            } else {
                toast.error('Coins are not enough');
            }
        }
    };

    const nextLevel = () => {
        if (Number(userAnswer) === currentQuestion.answer) {
            if (currentQuestionIndex + 1 === 10) {
                const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, textCryptoGame: { ...userInfoRiddle, levelDone: 10 } }
                updateUser(updateUserInfo)
                dispach(setUser(updateUserInfo))
                toast.success('Correct answer! 🎉');
                setInputFieldValue("")
                setActiveComponent(10)
            }
        } else {
            if (userInfo.balance - 100 >= 0) {
                const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, textCryptoGame: { ...userInfoRiddle, levelDone: currentQuestionIndex } }
                updateUser(updateUserInfo)
                dispach(setUser(updateUserInfo))
                setInputFieldValue("")
                toast.error('Incorrect answer. 100 coin deducted! ❌');
            } else {
                toast.error('Coins are not enough');
            }
        }
    };



    return (
        <div className="flex basis-full flex-col items-center justify-center">
            <div className='basis-4/6'>
                <h2 className="text-lg font-bold mb-2 text-center">Quiz Game</h2>
                <p className="text-sm text-gray-600 mb-4 text-center">Level {currentLevel}</p>
                <p className="text-sm text-gray-600 mb-4 text-center">Question {currentQuestionIndex + 1}/10</p>
                <Card className="p-4">
                    <h3 className="font-bold text-xl mb-2">{currentQuestion.textQuestion}</h3>
                    <div className="flex flex-col mb-4">
                        {currentQuestion.options.map((option, index) => (
                            <div key={`option_${index}`} className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    name="quiz-option"
                                    value={index}
                                    onChange={handleInputChange}
                                    checked={userAnswer == index}
                                />
                                <label className="ml-2">{option}</label>
                            </div>
                        ))}
                    </div>
                </Card>
            </div>
            {currentQuestionIndex < 9 && <Button variant="contained"
                onClick={checkAnswer}
                fullWidth
                sx={{
                    backgroundColor: '#87CEEB', // light sky blue color
                    color: 'white',
                    borderRadius: '20px',
                    marginTop: '5%',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#6495ED', // slightly darker shade on hover
                    },
                }}>
                Submit Answer
            </Button>}
            {currentQuestionIndex === 9 && (
                <Button variant="contained"
                    onClick={nextLevel}
                    fullWidth
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        marginTop: '5%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    Next Level
                </Button>
            )}
        </div>
    );
};

export default QuizGameComponent;