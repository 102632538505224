export const quizData = [
    {
        level: 1,
        entryCost: 100,
        wincose: 500,
        questions: [
            {
                "questionNumber": 1,
                "textQuestion": "What is the primary purpose of cryptocurrency?",
                "options": ["Store value", "Facilitate transactions", "Both A and B", "None of the above"],
                "answer": 2
            },
            {
                "questionNumber": 2,
                "textQuestion": "Which of the following is a well-known cryptocurrency?",
                "options": ["Bitcoin", "Ethereum", "Ripple", "All of the above"],
                "answer": 3
            },
            {
                "questionNumber": 3,
                "textQuestion": "What is a blockchain?",
                "options": ["A type of cryptocurrency", "A decentralized ledger technology", "A financial institution", "A digital wallet"],
                "answer": 1
            },
            {
                "questionNumber": 4,
                "textQuestion": "Which of the following is a characteristic of traditional finance?",
                "options": ["Centralized control", "High transaction fees", "Regulation by government", "All of the above"],
                "answer": 3
            },
            {
                "questionNumber": 5,
                "textQuestion": "What is a significant risk associated with cryptocurrencies?",
                "options": ["Market volatility", "Lack of regulation", "Security issues", "All of the above"],
                "answer": 3
            },
            {
                "questionNumber": 6,
                "textQuestion": "What is the term for the process of verifying transactions on a blockchain?",
                "options": ["Mining", "Staking", "Trading", "Lending"],
                "answer": 0
            },
            {
                "questionNumber": 7,
                "textQuestion": "Which of the following is NOT a benefit of cryptocurrencies?",
                "options": ["Anonymity", "Irreversibility", "Instant transactions", "Guaranteed profits"],
                "answer": 3
            },
            {
                "questionNumber": 8,
                "textQuestion": "What is the main difference between cryptocurrencies and traditional currencies?",
                "options": ["Cryptocurrencies are digital", "Traditional currencies are regulated", "Cryptocurrencies use blockchain technology", "All of the above"],
                "answer": 3
            },
            {
                "questionNumber": 9,
                "textQuestion": "What is a stablecoin?",
                "options": ["A cryptocurrency pegged to a stable asset", "A type of stock", "A digital currency issued by a government", "None of the above"],
                "answer": 0
            },
            {
                "questionNumber": 10,
                "textQuestion": "Which regulatory body oversees traditional financial markets in the US?",
                "options": ["SEC", "CFTC", "FINRA", "All of the above"],
                "answer": 3
            },
        ]
    },
    {
        level: 2,
        entryCost: 200,
        wincose: 1000,
        questions: [
            {
                "questionNumber": 11,
                "textQuestion": "What is an Initial Coin Offering (ICO)?",
                "options": ["A way to raise funds for new cryptocurrencies", "A type of cryptocurrency", "A government regulation", "A trading platform"],
                "answer": 0
            },
            {
                "questionNumber": 12,
                "textQuestion": "Which of the following is a decentralized finance (DeFi) platform?",
                "options": ["Uniswap", "Coinbase", "Robinhood", "PayPal"],
                "answer": 0
            },
            {
                "questionNumber": 13,
                "textQuestion": "What does 'HODL' mean in the cryptocurrency community?",
                "options": ["Hold on for dear life", "Sell immediately", "Invest heavily", "Diversify your portfolio"],
                "answer": 0
            },
            {
                "questionNumber": 14,
                "textQuestion": "What is a cryptocurrency wallet?",
                "options": ["A physical wallet for cash", "A digital tool to store cryptocurrencies", "A bank account", "A trading platform"],
                "answer": 1
            },
            {
                "questionNumber": 15,
                "textQuestion": "Which cryptocurrency was the first to be created?",
                "options": ["Ethereum", "Bitcoin", "Litecoin", "Ripple"],
                "answer": 1
            },
            {
                "questionNumber": 16,
                "textQuestion": "What is the primary function of a cryptocurrency exchange?",
                "options": ["To store cryptocurrencies", "To facilitate buying and selling of cryptocurrencies", "To mine cryptocurrencies", "To create new cryptocurrencies"],
                "answer": 1
            },
            {
                "questionNumber": 17,
                "textQuestion": "What is the term for the maximum supply of a cryptocurrency?",
                "options": ["Market cap", "Circulating supply", "Total supply", "Hard cap"],
                "answer": 3
            },
            {
                "questionNumber": 18,
                "textQuestion": "Which of the following is a feature of decentralized finance?",
                "options": ["Centralized control", "Smart contracts", "High fees", "Limited access"],
                "answer": 1
            },
            {
                "questionNumber": 19,
                "textQuestion": "What is a fork in cryptocurrency?",
                "options": ["A type of wallet", "A change in the protocol of a blockchain", "A trading strategy", "A type of exchange"],
                "answer": 1
            },
            {
                "questionNumber": 20,
                "textQuestion": "What is the primary advantage of using cryptocurrencies over traditional currencies?",
                "options": ["Lower transaction fees", "Higher volatility", "Government backing", "Physical form"],
                "answer": 0
            },
        ]
    },
    {
        level: 3,
        entryCost: 300,
        wincose: 1500,
        questions: [
            {
                "questionNumber": 21,
                "textQuestion": "What is the main purpose of a smart contract?",
                "options": ["To facilitate transactions automatically", "To store cryptocurrencies", "To create new cryptocurrencies", "To regulate the market"],
                "answer": 0
            },
            {
                "questionNumber": 22,
                "textQuestion": "Which of the following is a risk of investing in cryptocurrencies?",
                "options": ["Market manipulation", "Regulatory changes", "Security breaches", "All of the above"],
                "answer": 3
            },
            {
                "questionNumber": 23,
                "textQuestion": "What is the term for the process of converting cryptocurrency to cash?",
                "options": ["Trading", "Withdrawing", "Cash-out", "Liquidating"],
                "answer": 3
            },
            {
                "questionNumber": 24,
                "textQuestion": "What is the primary difference between a public and private blockchain?",
                "options": ["Public blockchains are open to everyone", "Private blockchains are faster", "Public blockchains are more secure", "Private blockchains are decentralized"],
                "answer": 0
            },
            {
                "questionNumber": 25,
                "textQuestion": "What is the purpose of a cryptocurrency mining pool?",
                "options": ["To store cryptocurrencies", "To combine resources for mining", "To trade cryptocurrencies", "To create new cryptocurrencies"],
                "answer": 1
            },
            {
                "questionNumber": 26,
                "textQuestion": "What does 'FOMO' stand for in cryptocurrency trading?",
                "options": ["Fear of missing out", "Fear of market order", "Fast online market operations", "Future of market options"],
                "answer": 0
            },
            {
                "questionNumber": 27,
                "textQuestion": "What is the main function of a cryptocurrency node?",
                "options": ["To mine new coins", "To validate transactions", "To create wallets", "To trade cryptocurrencies"],
                "answer": 1
            },
            {
                "questionNumber": 28,
                "textQuestion": "What is the term for a cryptocurrency that is pegged to a traditional currency?",
                "options": ["Stablecoin", "Altcoin", "Token", "Fiat currency"],
                "answer": 0
            },
            {
                "questionNumber": 29,
                "textQuestion": "Which of the following is a common use case for blockchain technology?",
                "options": ["Supply chain management", "Social media", "Email services", "Video streaming"],
                "answer": 0
            },
            {
                "questionNumber": 30,
                "textQuestion": "What is the primary goal of cryptocurrency regulation?",
                "options": ["To eliminate cryptocurrencies", "To protect investors and prevent fraud", "To promote cryptocurrencies", "To centralize control"],
                "answer": 1
            }
        ]
    }
];