import React from "react";
import ActionAreaCard from "../../Components/Cards/index";
import ChosseCandidate from "./../ChooseCandidate/index";
import { useDispatch, useSelector } from 'react-redux';
import InnerBattle from "./BattleInner";
import RiddleGame from  "./Riddle";
import Choose from "./Choose";
import { setTour } from "../../reducer";
import VisualRiddle from "./VisualRiddle";
import VisualRiddleGameComponent from "./VisualRiddleGame";
import QuizGameComponent from "./TextGame";
import TextLevel from "./TextLevel";
import RiddleGameWin from "./RiddleGameWin";
import TextWin from "./TextWin";

function HomeCard() {
  const dispatch = useDispatch();
  const tour = useSelector((state) => state.user.tour);
  const [activeComponent, setActiveComponent] = React.useState(0);
  React.useEffect(() => {
    if (activeComponent === 2 && !tour)
    dispatch(setTour(true))
  }, [activeComponent])
  return (
    <div className="p-[5%] flex basis-full justify-center">
        {activeComponent === 0 && <ActionAreaCard setActiveComponent={(data) => setActiveComponent(data)}  />}
        {activeComponent === 1 && <ChosseCandidate setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 2 && <Choose setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 3 && <InnerBattle setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 4 && <RiddleGame setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 5 && <VisualRiddle setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 6 && <VisualRiddleGameComponent setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 7 && <RiddleGameWin setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 8 && <TextLevel setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 9 && <QuizGameComponent setActiveComponent={(data) => setActiveComponent(data)} />}
        {activeComponent === 10 && <TextWin setActiveComponent={(data) => setActiveComponent(data)} />}
    </div>
  );
}

export default HomeCard;
