import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const TelegramContext = createContext({});

const TelegramProvider = ({ children }) => {
  const [webApp, setWebApp] = useState(null);

  useEffect(() => {
    const loadTelegramScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = true;
        script.onload = () => resolve();
        document.body.appendChild(script);
      });
    };

    const initializeTelegram = async () => {
      await loadTelegramScript();
      const app = window.Telegram?.WebApp;
      if (app) {
        app.ready();
        app.expand();
        setWebApp(app);
      } else {
        console.error('Telegram WebApp is not available.');
      }
    };
    initializeTelegram();
  }, []);

  const  user = {
    id: 1147137437,
    first_name: 'Banshi',
    last_name: '',

  }

  const value = useMemo(() => {
    return webApp
      ? {
          webApp,
          unsafeData: webApp.initDataUnsafe,
          user: webApp.initDataUnsafe.user
        }
      : {};
  }, [webApp]);

  return (
    <TelegramContext.Provider value={value}>
      {children}
    </TelegramContext.Provider>
  );
};

export const useTelegram = () => useContext(TelegramContext);

export default TelegramProvider;