import React from "react";
import TourThreeLogo from "../../Assets/TourScreen/Screen3.png";
import DotsMobileStepper from "../Stepper/index";
import Button from '@mui/material/Button';

function TourTwo({ setActiveStep, setSkip }) {
    return (
        <div className="flex flex-col w-full h-[100dvh] justify-between">
            <div className="flex w-full flex-col p-[5%] mt-[10%]">
                <div className="flex w-[60%]">
                    <img
                        src={TourThreeLogo}
                        alt=""
                        style={{
                            width: "80%",
                            height: "auto",
                            objectFit: "cover"
                        }}
                    />
                </div>
                <div className="p-[5%]">
                    <p className="text-2xl font-bold">Claim Victory Blocks</p>
                    <p className="text-lg">Win battles and have your achievements saved on the blockchain for all to see.</p>
                </div>
                <div className="p-[2%]">
                    <DotsMobileStepper activeStep={2} />
                </div>
            </div>
            <div className="p-[5%] flex flex-row w-full justify-between">
            <Button onClick={() => setSkip()} variant="text">Skip</Button>
                <Button variant="contained"
                    onClick={() => setActiveStep()}
                    sx={{
                        backgroundColor: '#87CEEB', // light sky blue color
                        color: 'white',
                        borderRadius: '20px',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#6495ED', // slightly darker shade on hover
                        },
                    }}>
                    Join the Battle!
                </Button>
            </div>
        </div>
    );
}

export default TourTwo;
