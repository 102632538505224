import DragHandleIcon from '@mui/icons-material/DragHandle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import { FaDivide } from "react-icons/fa";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RemoveIcon from '@mui/icons-material/Remove';
import CropSquareIcon from '@mui/icons-material/CropSquare';


export const iconWithId = [
  {
    id: 1,
    icon: <ChangeHistoryIcon sx={{ fontSize: "4dvh" }} />, // Triangle icon
  },
  {
    id: 2,
    icon: <PanoramaFishEyeIcon sx={{ fontSize: "4dvh" }} />, // Circle icon
  },
  {
    id: 3,
    icon: <StarBorderIcon sx={{ fontSize: "4dvh" }} />, // Star icon
  },
  {
    id: 4,
    icon: <AddIcon sx={{ fontSize: "4dvh" }} />, // Plus icon
  },
  {
    id: 5,
    icon: <RemoveIcon sx={{ fontSize: "4dvh" }} />, // Minus icon
  },
  {
    id: 6,
    icon: <CloseIcon sx={{ fontSize: "4dvh" }} />, // Multiplication icon
  },
  {
    id: 7,
    icon: <DragHandleIcon sx={{ fontSize: "4dvh" }} />, // Equals icon
  },
  {
    id: 8,
    icon: <QuestionMarkIcon sx={{ fontSize: "4dvh" }} />, // Question icon
  },
  {
    id: 9,
    icon: <CropSquareIcon sx={{ fontSize: "4dvh" }} />, // Squar icon
  },
  {
    id: 10,
    icon: <FaDivide size={25} />, // Divide icon
  }
];





export const VisualRiddleGame = [
    {
      level: 1,
      entryCost: 100,
      wincose: 500,
      questions: [
        {
          row: [
            { iconsId: [9, 10, 2, 7], number: 2 },
            { iconsId: [9, 6, 2,7], number: 2 },
            { iconsId: [9,5,2,7,8], number: false },            
          ],
          question: 1,
          answer: 1
        },
        {
          row: [
            { iconsId: [1, 4, 1, 4, 1, 7], number: 9 },
            { iconsId: [1, 4, 2, 7], number: 13 },
            { iconsId: [2, 5, 9, 7], number: 4 },
            { iconsId: [9, 4, 1, 4, 2, 7, 8], number: false }
          ],
          question: 2,
          answer: 19
        },
        {
          row: [
            { iconsId: [1, 4, 1, 4, 1, 7], number: 12 },
            { iconsId: [1, 4, 2, 4, 2, 7], number: 8 },
            { iconsId: [2, 4, 9, 4, 9, 7], number: 8 },
            { iconsId: [1, 4, 9, 4, 9, 7, 8], number: false }
          ],
          question: 3,
          answer: 10
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 12 },
            { iconsId: [1,4,2,4,2,7], number: 8 },
            { iconsId: [2,4,9,4,9,7], number: 8 },
            { iconsId: [2,4,9,5,1,7,8], number: false }
          ],
          question: 4,
          answer: 1
        },
        {
          row: [
            { iconsId: [1, 4, 1, 4, 1, 7], number: 27 },
            { iconsId: [1, 4, 2, 4, 2, 7], number: 21 },
            { iconsId: [1, 4, 2, 4, 9, 7], number: 19 },
            { iconsId: [9,4,1,5,2,7,8], number: false }
          ],
          question: 5,
          answer: 7
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 27 },
            { iconsId: [1,4,2,4,2,7], number: 21 },
            { iconsId: [1,4,2,4,9,7], number: 19 },
            { iconsId: [2,4,9,5,1,7,8], number: false }
          ],
          question: 6,
          answer: 1
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 30 },
            { iconsId: [2,4,2,5,1,7], number: 22 },
            { iconsId: [2,5,9,7], number: 11 },
            { iconsId: [1,4,2,4,9,8], number: false }
          ],
          question: 7,
          answer: 31
        },
        {
          row: [
            { iconsId: [2,4,2,7], number: 10 },
            { iconsId: [2,6,9,4,9,7], number: 12 },
            { iconsId: [2,6,9,5,1,6,2,7], number: 5 },
            { iconsId: [1,7,8], number: false }
          ],
          question: 8,
          answer: 1
        },
        {
          row: [
            { iconsId: [1,6,1,6,1,7], number: 8 },
            { iconsId: [1,6,1,6,2,7], number: 12 },
            { iconsId: [2,6,9,7], number: 15 },
            { iconsId: [1,6,2,6,9,7,8], number: false }
          ],
          question: 9,
          answer: 30
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 18 },
            { iconsId: [1,4,2,4,2,7], number: 10 },
            { iconsId: [2,5,9,7], number: 1 },
            { iconsId: [9,4,1,4,2,7,8], number: false }
          ],
          question: 10,
          answer: 9
        }
      ]
    },
    {
      level: 2,
      entryCost: 200,
      wincose: 1000,
      questions: [
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 24 },
            { iconsId: [2,4,1,4,2,7], number: 14 },
            { iconsId: [9,4,2,4,1,7], number: 16 },
            { iconsId: [1,5,9,4,2,5,9,7,8], number: false }
          ],
          question: 1,
          answer: 1
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 9 },
            { iconsId: [1,4,9,7], number: 13 },
            { iconsId: [9,5,2,7], number: 4 },
            { iconsId: [2,4,1,4,9,7,8], number: false }
          ],
          question: 2,
          answer: 19
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 36 },
            { iconsId: [1,4,2,4,2,7], number: 28 },
            { iconsId: [2,5,9,7], number: 3 },
            { iconsId: [1,4,2,5,9,7,8], number: false }
          ],
          question: 3,
          answer: 15
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 12 },
            { iconsId: [2,4,1,7], number: 14 },
            { iconsId: [2,5,9,7], number: 3 },
            { iconsId: [9,4,2,4,1,7,8], number: false }
          ],
          question: 4,
          answer: 21
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 30 },
            { iconsId: [2,4,2,4,2,7], number: 21 },
            { iconsId: [9,4,9,4,9,7], number: 18 },
            { iconsId: [1,4,2,4,9,7,8], number: false }
          ],
          question: 5,
          answer: 23
        },
        {
          row: [
            { iconsId: [1,4,2,7], number: 50 },
            { iconsId: [2,4,2,4,2,7], number: 120 },
            { iconsId: [1,4,9,7], number: 35 },
            { iconsId: [9,7,8], number: false }
          ],
          question: 6,
          answer: 25
        },
        {
          row: [ 
            { iconsId: [1,4,1,4,1,7], number: 30 },
            { iconsId: [2,4,2,4,1,7], number: 20 },
            { iconsId: [9,4,9,4,2,7], number: 13 },
            { iconsId: [2,4,1,4,9,7,8], number: false }
          ],
          question: 7,
          answer: 19
        },
        {
          row: [
            { iconsId: [1, 4, 1, 7], number: 8 },
            { iconsId: [1,4,9,4,9,7], number: 14 },
            { iconsId: [9,4,2,4,2,7], number: 19 },
            { iconsId: [2,4,3,4,2,7], number: 19 },
            { iconsId: [2,4,9,6,1,7,8], number: false },
          ],
          question: 8,
          answer: 27
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 30 },
            { iconsId: [1,4,2,4,2,7], number: 22 },
            { iconsId: [2,5,9,4,9,7], number: 6 },
            { iconsId: [1,4,1,6,2,7,8], number: false }
          ],
          question: 9,
          answer: 70
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 12 },
            { iconsId: [2,4,2,7], number: 18 },
            { iconsId: [9,4,9,7], number: 12 },
            { iconsId: [1,4,2,4,9,7,8], number: false }
          ],
          question: 10,
          answer: 19
        }
      ]
    },
    {
      level: 3,
      entryCost: 300,
      wincose: 1500,
      questions: [
        {
          row: [
            { iconsId: [1,4,1,7], number: 12 },
            { iconsId: [2,4,1,7], number: 11 },
            { iconsId: [9,4,2,7], number: 9 },
            { iconsId: [2,7,8], number: false }
          ],
          question: 1,
          answer: 5
        },
        {
          row: [
            { iconsId: [1,4,2,7], number: 5 },
            { iconsId: [9,4,1,7], number: 6 },
            { iconsId: [2,4,9,7], number: 7 },
            { iconsId: [1,7,8], number: false }
          ],
          question: 2,
          answer: 2
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 30 },
            { iconsId: [1,4,9,4,9,7], number: 16 },
            { iconsId: [9,5,2,7], number: 1 },
            { iconsId: [2,4,1,4,9,7,8], number: false }
          ],
          question: 3,
          answer: 15
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 15 },
            { iconsId: [1,4,2,4,2,7], number: 9 },
            { iconsId: [2,5,9,7], number: 1 },
            { iconsId: [2,4,9,4,1,7,8], number: false }
          ],
          question: 4,
          answer: 8
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 12 },
            { iconsId: [2,4,9,7], number: 17 },
            { iconsId: [2,4,1,7], number: 13 },
            { iconsId: [2,4,1,4,9,7,8], number: false }
          ],
          question: 5,
          answer: 23
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 6 },
            { iconsId: [2,4,1,7], number: 9 },
            { iconsId: [9,4,2,7], number: 7 },
            { iconsId: [1,4,9,7,8], number: false }
          ],
          question: 6,
          answer: 4
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 10 },
            { iconsId: [1,4,2,7], number: 11 },
            { iconsId: [9,6,2,7], number: 120 },
            { iconsId: [1,4,9,7,8], number: false }
          ],
          question: 7,
          answer: 25
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 20 },
            { iconsId: [2,4,1,7], number: 15 },
            { iconsId: [9,4,2,7], number: 8 },
            { iconsId: [2,7,8], number: false }
          ],
          question: 8,
          answer: 5
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 9 },
            { iconsId: [1,4,2,7], number: 13 },
            { iconsId: [2,5,9,7], number: 4 },
            { iconsId: [1,4,2,4,9,7,8], number: false }
          ],
          question: 9,
          answer: 19
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 12 },
            { iconsId: [2,4,1,7], number: 15 },
            { iconsId: [1,4,9,7], number: 14 },
            { iconsId: [9,7,8], number: false }
          ],
          question: 10,
          answer: 5
        }
      ]
    },
    {
      level: 4,
      entryCost: 400,
      wincose: 2000,
      questions: [
        {
          row: [
            { iconsId: [1,4,2,7], number: 20 },
            { iconsId: [1,4,1,7], number: 12 },
            { iconsId: [9,4,1,7], number: 18 },
            { iconsId: [2,7,8], number: false }
          ],
          question: 1,
          answer: 14
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 30 },
            { iconsId: [2,2,4,2,2,7], number: 20 },
            { iconsId: [9,4,9,7], number: 8 },
            { iconsId: [1,4,2,4,9,7,8], number: false }
          ],
          question: 2,
          answer: 24
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 39 },
            { iconsId: [1,4,2,4,2,7], number: 21 },
            { iconsId: [2,5,9,7], number: 2 },
            { iconsId: [9,4,2,4,1,7,8], number: false }
          ],
          question: 3,
          answer: 19
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 12 },
            { iconsId: [1,4,2,4,2,7], number: 10 },
            { iconsId: [2,4,9,4,9,7], number: 13 },
            { iconsId: [2,6,9,6,1,7,8], number: false }
          ],
          question: 4,
          answer: 60
        },
        {
          row: [
            { iconsId: [1,4,1,4,1,7], number: 9 },
            { iconsId: [1,4,2,4,2,4,2,7], number: 18 },
            { iconsId: [2,4,9,7], number: 10 },
            { iconsId: [9, 7, 8], number: false }
          ],
          question: 5,
          answer: 5
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 18 },
            { iconsId: [1,6,2,4,2,7], number: 20 },
            { iconsId: [1,6,2,5,3,6,1,7], number: 9 },
            { iconsId: [3,7,8], number: false }
          ],
          question: 6,
          answer: 1
        },
        {
          row: [
            { iconsId: [1,4,1,7], number: 24 },
            { iconsId: [1,6,2,4,2,7], number: 26 },
            { iconsId: [1,6,2,5,3,6,1,7], number: 12 },
            { iconsId: [3, 7, 8], number: false }
          ],
          question: 7,
          answer: 1
        },
        {
          row: [
            { iconsId: [1, 4, 1, 7], number: 16 },
            { iconsId: [1,6,2,4,2,7], number: 45 },
            { iconsId: [1,6,2,5,3,6,1,7], number: 8 },
            { iconsId: [3, 7, 8], number: false }
          ],
          question: 8,
          answer: 4
        },
        {
          row: [
            { iconsId: [1, 4, 1, 7], number: 30 },
            { iconsId: [1,6,2,4,2,7], number: 48 },
            { iconsId: [1,6,2,5,3,6,1,7], number: 15 },
            { iconsId: [3, 7, 8], number: false }
          ],
          question: 9,
          answer: 2
        },
        {
          row: [
            { iconsId: [1, 4, 1, 7], number: 14 },
            { iconsId: [1, 6, 2, 4, 2, 7], number: 32 },
            { iconsId: [1, 6, 2, 5, 3, 6, 1, 7], number: 7 },
            { iconsId: [3, 7, 8], number: false }
          ],
          question: 10,
          answer: 3
        }
      ]
    }
  ];
  
