import React from "react";
import TourOne from "./TourOne";
import TourTwo from "./TourTwo";
import TourThree from "./TourThree";

function Tour({ setSkip }) {
    const [activeStep, setActiveStep] = React.useState(0);
    React.useEffect(() => {
        if (activeStep === 3) {
            setSkip();
            localStorage.setItem("tour", "true");
        }
    }, [activeStep])
    return (
        <div  className="">

            {activeStep === 0  && <TourOne setSkip={() => setSkip()} setActiveStep={() => setActiveStep(activeStep + 1)} />}
            {activeStep === 1  && <TourTwo setSkip={() => setSkip()} setActiveStep={() => setActiveStep(activeStep + 1)} />}
            {activeStep === 2  && <TourThree setSkip={() => setSkip()} setActiveStep={() => setActiveStep(activeStep + 1)} />}
        </div>
    );
}

export default Tour;
