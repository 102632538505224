import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { VisualRiddleGame, iconWithId } from '../../RiddleGameFile/index';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from "../../api";
import { setUser } from "../../reducer";


const VisualRiddleGameComponent = ({ setActiveComponent }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const userInfoRiddle = useSelector((state) => state.user.userInfo.riddleGame);
  const dispach = useDispatch();
  const [currentLevel, setCurrentLevel] = useState(userInfoRiddle.level);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(userInfoRiddle.levelDone);
  const [userAnswer, setUserAnswer] = useState('');
  const [nextLevelState, setNextLevelState] = useState(false);
  const currentLevelData = VisualRiddleGame.find(level => level.level === currentLevel);
  const currentQuestion = currentLevelData.questions[currentQuestionIndex];

  const handleInputChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const checkAnswer = async () => {
    if (userAnswer === currentQuestion.answer.toString()) {
      if (currentQuestionIndex + 1 === 10) {
        setNextLevelState(true);
        const updateUserInfo = { ...userInfo, balance: userInfo.balance + 500, riddleGame: { ...userInfoRiddle, levelDone: currentQuestionIndex + 1 } };
        toast.success('Correct answer! 🎉');
        updateUser(updateUserInfo);
        dispach(setUser(updateUserInfo))
      } else {
        const updateUserInfo = { ...userInfo, balance: userInfo.balance + 500, riddleGame: { ...userInfoRiddle, levelDone: currentQuestionIndex + 1 } };
        toast.success('Correct answer! 🎉');
        setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % currentLevelData.questions.length);
        setUserAnswer('');
        updateUser(updateUserInfo);
        dispach(setUser(updateUserInfo))
      }
    } else {
      if (userInfo.balance - 100 >= 0) {
        const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, riddleGame: { ...userInfoRiddle, levelDone: currentQuestionIndex } }
        updateUser(updateUserInfo)
        dispach(setUser(updateUserInfo))
        toast.error('Incorrect answer. 100 coin deducted! ❌');
      } else {
        toast.error('Coins are not enough');
      }
    }
  };

  const nextLevel = () => {
    if (userAnswer === currentQuestion.answer.toString()) {
      if (currentQuestionIndex + 1 === 10) {
        const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, riddleGame: { ...userInfoRiddle, levelDone: 10 } }
        updateUser(updateUserInfo)
        dispach(setUser(updateUserInfo))
        toast.success('Correct answer! 🎉');
        setActiveComponent(7)
      }
    } else {
      if (userInfo.balance - 100 >= 0) {
        const updateUserInfo = { ...userInfo, balance: userInfo.balance - 100, riddleGame: { ...userInfoRiddle, levelDone: currentQuestionIndex } }
        updateUser(updateUserInfo)
        dispach(setUser(updateUserInfo))
        toast.error('Incorrect answer. 100 coin deducted! ❌');
      } else {
        toast.error('Coins are not enough');
      }
    }
  };


  return (
    <div className="flex basis-full flex-col items-center justify-center">
      <div className='basis-4/6'>
        <div className="flex flex-col items-start justify-start">
          <IconButton size="large" onClick={() => setActiveComponent(5)}>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <h2 className="text-lg font-bold mb-2 text-center">Crypto Economics</h2>
        <p className="text-sm text-center mb-5">Pick a topic and get ready to choose a side.</p>
        <p className="text-sm text-gray-600 mb-4 text-center">Level {currentLevel}</p>
        <p className="text-sm text-gray-600 mb-4 text-center">Question {currentQuestionIndex + 1}</p>
        <Card className='min-h-[4dvh]'>
          <div className="flex flex-wrap justify-center mb-4 items-center">
            {currentQuestion.row.map((item, index) => (
              <div key={index} className="flex basis-full flex-row justify-center items-center">
                {item.iconsId.map((iconId, iconIndex) => (
                  <div key={iconIndex} className="mb-4">
                    {iconWithId.find(icon => icon.id === iconId).icon}
                  </div>
                ))}
                <p className="font-bold text-[4dvh] text-center mb-4" >{item.number}</p>
              </div>
            ))}
          </div>
        </Card>
      </div>
      <div className='basis-2/12 p-[1dvh] w-full'>
        <TextField
          label="Enter your answer"
          id="outlined-size-small"
          className='max-h-[4dvh] min-h-[4dvh]'
          value={userAnswer}
          fullWidth
          disabled={currentQuestionIndex === 10}
          onChange={handleInputChange}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px', // Adjust the radius as needed
            },
          }}
        />
      </div>
      {currentQuestionIndex < 9 && (
        <Button variant="contained"
          onClick={checkAnswer}
          fullWidth
          sx={{
            backgroundColor: '#87CEEB', // light sky blue color
            color: 'white',
            borderRadius: '20px',
            marginTop: '5%',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#6495ED', // slightly darker shade on hover
            },
          }}>
          Solve
        </Button>)}
      {(currentQuestionIndex === 9 || currentQuestionIndex === 10) && (
        <Button variant="contained"
          onClick={nextLevel}
          fullWidth
          sx={{
            backgroundColor: '#87CEEB', // light sky blue color
            color: 'white',
            borderRadius: '20px',
            marginTop: '5%',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#6495ED', // slightly darker shade on hover
            },
          }}>
          Next Level
        </Button>
      )}
    </div>
  );
};

export default VisualRiddleGameComponent;
